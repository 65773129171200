function Header({ name }) {
  // const [open, setOpen] = useState("");

  return (
    <section
      className="hero-wrap hero-wrap-2"
      style={{
        backgroundImage:
          "url(" +
          "https://res.cloudinary.com/dl91pivxv/image/upload/v1715772372/Mahogany_Fern_by_Baingor_Joiner_2018-0638_gkhgvr.jpg" +
          ")",
      }}
      data-stellar-background-ratio="0.5"
    >
      <div className="overlay"></div>
      <div className="container">
        <div className="row no-gutters slider-text align-items-center justify-content-center">
          <div className="col-md-9  text-center">
            <h1 className="mb-2 bread">{name}</h1>
            <p className="breadcrumbs">
              <span className="mr-2">
                <a href="/">
                  Home <i className="ion-ios-arrow-forward"></i>
                </a>
              </span>{" "}
              <span>
                {name}
                <i className="ion-ios-arrow-forward"></i>
              </span>
            </p>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Header;
