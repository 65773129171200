import ContactComponent from "../components/ContactComponent";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import Navbar from "../layouts/Navbar";

function Contact() {
  return (
    <>
      <Navbar />
      <Header name="Contact Us" />
      <ContactComponent />
      <Footer />
    </>
  );
}

export default Contact;
