function Navbar() {
  const navB = [
    {
      name: "home",
      route: "/",
    },
    {
      name: "about",
      route: "/about",
    },

    {
      name: "services",
      route: "/services",
    },
    {
      name: "gallery",
      route: "/gallery",
    },
    {
      name: "shop",
      route: "/shop",
    },

    {
      name: "contact",
      route: "/contact",
    },
  ];

  return (
    <nav
      className="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light"
      id="ftco-navbar"
    >
      <div className="container">
        <a className="navbar-brand" href="/">
          {" "}
          <img
            src="https://mandf.vercel.app/img/log.png"
            alt="logo"
            width="150px"
          />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#ftco-nav"
          aria-controls="ftco-nav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="oi oi-menu"></span>
        </button>

        <div className="collapse navbar-collapse" id="ftco-nav">
          <ul className="navbar-nav ml-auto">
            {navB.map((nav) => (
              <li
                className={
                  sessionStorage.getItem("open") === nav.name
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <a
                  href={nav.route}
                  onClick={() => sessionStorage.setItem("open", nav.name)}
                  className="nav-link"
                >
                  {nav.name.toUpperCase()}
                </a>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default Navbar;
