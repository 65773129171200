/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../pages/firebaseConfig";
import { toast } from "react-toastify";

const EditProducts = ({ view, setView, state, fetchProducts }) => {
  const [fields, setFields] = useState(state);

  const [isLoading, setIsLoading] = useState(false);

  const handleClose = () => {
    setView(false);
  };

  useEffect(() => {
    setFields(state);
  }, [state]);

  const onSubmit = async (e) => {
    e.preventDefault();

    handleUpdate(fields);
  };

  const handleUpdate = async (fields) => {
    setIsLoading(true);

    try {
      var docRef = doc(firestore, "products", fields.id);

      await updateDoc(docRef, {
        title: fields.title,
        description: fields.description,
        category: fields.category,
        sold: fields.sold,
        status: fields.status,
      });
      toast.success("Document updated successfully");
      setIsLoading(false);
      handleClose();
      await fetchProducts();
    } catch (error) {
      setIsLoading(false);
      //   console.log(error);
      toast.error("Failed to update document");
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFields({ ...fields, [name]: value });
  };

  return (
    <Modal show={view} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title style={{ paddingLeft: "2rem" }}>Edit Products</Modal.Title>
        <form onSubmit={onSubmit}>
          <div className="form-group">
            <input
              type="text"
              value={fields.title}
              name="title"
              onChange={handleChange}
              placeholder="Title"
              className="form-control"
            />
          </div>
          <div className="form-group">
            <select
              type="text"
              value={fields.category}
              name="category"
              onChange={handleChange}
              className="form-control"
            >
              <option value="">Select Category</option>
              <option value="">Select Category</option>
              <option value="furniture">Furniture</option>
              <option value="lighting">Lighting</option>
              <option value="vases">Vases/Pots</option>
              <option value="mirror">Mirrors</option>
              <option value="plants">Plants</option>
              <option value="accessories">Accessories</option>
              <option value="wallart">Wall Art</option>
            </select>
          </div>
          <div className="form-group">
            <select
              type="text"
              value={fields.status}
              onChange={handleChange}
              name="status"
              required
              className="form-control"
            >
              <option value="false">Select Status</option>
              <option value="true">Latest</option>
              {/* <option value="lighting">Lighting</option> */}
            </select>
          </div>
          <div className="form-group">
            <select
              type="text"
              value={fields.sold}
              onChange={handleChange}
              name="sold"
              required
              className="form-control"
            >
              <option value="">Select Sold out status</option>
              <option value="true">Sold</option>
              <option value="false">Not Sold</option>
              {/* <option value="lighting">Lighting</option> */}
            </select>
          </div>

          <div className="form-group">
            <textarea
              class="form-control"
              name="description"
              value={fields.description}
              onChange={handleChange}
              placeholder="Description"
            ></textarea>
          </div>

          <div className="form-group">
            {isLoading ? (
              <input
                type="submit"
                value="Submitting"
                className="btn btn-primary py-3 px-5"
              />
            ) : (
              <input
                type="submit"
                value="Send Message"
                className="btn btn-primary py-3 px-5"
              />
            )}
          </div>
        </form>
      </Modal.Header>
    </Modal>
  );
};

export default EditProducts;
