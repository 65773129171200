import React, { useEffect, useState } from "react";
import { db, firestore, storage } from "./firebaseConfig";
import { doc, deleteDoc } from "firebase/firestore";
import { ref, deleteObject } from "firebase/storage";
import { collection, getDocs } from "firebase/firestore";
import Navbar from "../layouts/Navbar";
import { toast } from "react-toastify";
import { queries } from "@testing-library/react";
import EditProducts from "../components/EditProducts";
import ImageViewer from "../components/ImageViewer";
import AdminNavbar from "../layouts/AdminNavbar";
import Footer from "../layouts/Footer";

const Products = () => {
  const access = sessionStorage.getItem("mandftoken");

  if (!access) {
    window.location.href = "/";
    sessionStorage.setItem("mandftoken", "");
    toast.info("You are not allowed to view this page.");
  }
  const [products, setProducts] = useState([]);
  const [query, setQuery] = useState("");
  const [view, setView] = useState(false);
  const [show, setShow] = useState(false);
  const [state, setState] = useState("");
  const [files, setFiles] = useState([]);

  const fetchProducts = async () => {
    const querySnapshot = await getDocs(collection(db, "products"));
    const productsList = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }));
    setProducts(productsList);
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  const handleView = async (
    index,

    imagePath
  ) => {
    console.log(imagePath);
    setShow(true);

    setFiles(imagePath);
  };
  const handleEdit = async (
    index,
    docId,
    docTitle,
    docDescription,
    docCategory,
    docSold,
    docStatus,
    imagePath
  ) => {
    setView(true);
    const data = {
      id: docId,
      title: docTitle,
      description: docDescription,
      category: docCategory,
      status: docStatus,
      sold: docSold,
      imagePath,
    };
    setState(data);
  };

  const handleDelete = async (index, docId, imagePaths) => {
    console.log(`Deleting record at index: ${index}`);
    try {
      // Step 1: Delete the document from Firestore
      const docRef = doc(firestore, "products", docId);
      await deleteDoc(docRef);

      toast.success("Document deleted from Firestore");

      // Step 2: Delete the image files from Firebase Storage
      const deletePromises = imagePaths.map((imagePath) => {
        const storageRef = ref(storage, imagePath);
        return deleteObject(storageRef);
      });

      await Promise.all(deletePromises);
      toast.success("Image files deleted from storage");

      // Update local state to remove the deleted product
      setProducts(products.filter((_, i) => i !== index));
    } catch (error) {
      console.error("Error deleting document and images:", error);
      toast.error("Error deleting document and images:", error);
    }
  };

  //
  // console.log(products);
  return (
    <>
      <AdminNavbar />
      <div class="container">
        <input
          class="form-control"
          type="text"
          placeholder="Enter title or category"
          value={query}
          onChange={(e) => setQuery(e.target.value)}
        />
        <table class="table table-hover">
          <thead>
            <tr>
              <th>S/N</th>
              <th>ID</th>
              <th>Title</th>
              <th>Description</th>
              <th>Category</th>
              <th>Image</th>
              <th>Edit</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {products && products.length ? (
              products
                .filter((product) => {
                  if (
                    query == "" ||
                    product.title.toLowerCase().includes(query.toLowerCase()) ||
                    product.category.toLowerCase().includes(query.toLowerCase())
                  ) {
                    return true; // This product matches the query or query is empty
                  }
                  return false; // This product does not match the query
                })
                .map((product, i) => {
                  return (
                    <tr key={i}>
                      <td>{i + 1}</td>
                      <td>{product.id}</td>
                      <td>{product.title}</td>

                      <td>{product.description}</td>
                      <td>{product.category}</td>
                      <td>
                        <button
                          className=""
                          onClick={() => handleView(i, product.imageUrls)}
                        >
                          View Images
                        </button>
                      </td>
                      {/* <td>{<img src={product.imageUrl} width="30%"></img>}</td> */}
                      <td>
                        <button
                          className=""
                          onClick={() =>
                            handleEdit(
                              i,
                              product.id,
                              product.title,
                              product.description,
                              product.category,
                              product.imageUrls,
                              product.status,
                              product.sold
                            )
                          }
                        >
                          Edit Record
                        </button>
                      </td>
                      <td>
                        <button
                          className=""
                          onClick={() =>
                            handleDelete(i, product.id, product.imageUrl)
                          }
                        >
                          Delete Record
                        </button>
                      </td>
                    </tr>
                  );
                })
            ) : (
              <tr>
                <td>No records</td>{" "}
              </tr>
            )}
          </tbody>
        </table>
        <EditProducts
          view={view}
          setView={setView}
          state={state}
          fetchProducts={fetchProducts}
        />
        <ImageViewer show={show} setShow={setShow} file={files} />
      </div>
      <Footer />
    </>
  );
};

export default Products;
