import { useEffect } from "react";
import useFetch from "../hooks/useFetch";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";

function GalleryComponent() {
  const { getImages, items, loading } = useFetch();

  useEffect(() => {
    getImages();
  }, []);
  return (
    <section className="ftco-section bg-light">
      <div className="container">
        <div className="row justify-content-start mb-5 pb-2">
          <div className="col-md-4 heading-section ">
            <h2 className="mb-4"> GALLERY</h2>
            <span className="subheading subheading-with-line">
              <small className="pr-2 bg-light">our showcase</small>
            </span>
          </div>
        </div>
      </div>
      <div className="container">
        {!items.length ? (
          <SkeletonTheme baseColor="#ffffff" highlightColor="#a33e21">
            <p>
              <Skeleton count={10} />
            </p>
          </SkeletonTheme>
        ) : (
          <div className="row">
            {items.length &&
              items.map((item) => (
                <div className="project figure col-md-6 col-lg-3 mb-4">
                  <img src={item.url} className="img-fluid bluur" alt="mandf" />

                  <a
                    href={item.url}
                    className="icon image-popup d-flex justify-content-center align-items-center"
                  >
                    <span className="icon-expand"></span>
                  </a>
                </div>
              ))}
          </div>
        )}
      </div>
    </section>
  );
}

export default GalleryComponent;
