import { useState } from "react";
import logo from "../assets/logo.png";

function Footer() {
  const [fields, setFields] = useState("");

  const [status, setStatus] = useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFields({ ...fields, [name]: value });
  };

  const sendFeedback = (templateId, variables) => {
    setStatus(true);
    window.emailjs
      .send("service_tbknuui", templateId, variables)
      .then((res) => {
        setStatus(false);
        setFields("");
      })
      // Handle errors here however you like, or use a React error boundary
      .catch((err) => {
        setStatus(false);
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateId = "template_mdm12yx";
    const variables = {
      message: fields.email,
      from_name: "Newsletter",
      reply_to: fields.email,
    };
    sendFeedback(templateId, variables);
  };
  return (
    <footer className="ftco-footer ftco-bg-dark ftco-section">
      <div className="container">
        <div className="row mb-5">
          <div className="col-md">
            <div className="ftco-footer-widget mb-4">
              <h2 className="ftco-heading-2">
                {" "}
                <a className="navbar-brand" href="/">
                  {" "}
                  <img
                    src="https://mandf.vercel.app/img/log.png"
                    alt="logo"
                    width="150px"
                  />
                </a>
              </h2>
              <p>
                We bring sophistication, functionality, and a touch of your
                personality to every project.
              </p>
            </div>
          </div>
          <div className="col-md">
            <div className="ftco-footer-widget mb-4 ml-md-4">
              <h2 className="ftco-heading-2">Let's be social</h2>
              <ul className="ftco-footer-social list-unstyled float-md-left float-lft mt-3">
                <li className="">
                  <a href="https://www.instagram.com/mandfinteriors_/">
                    <span className="icon-instagram"></span>
                  </a>
                </li>
              </ul>
            </div>
          </div>

          <div className="col-md">
            <div className="ftco-footer-widget mb-4">
              <h2 className="ftco-heading-2">Newsletter</h2>
              <p>
                Far far away, behind the word mountains, far from the countries.
              </p>
              <form className="subscribe-form" onSubmit={handleSubmit}>
                <div className="form-group">
                  <input
                    type="text"
                    className="form-control mb-2 text-center"
                    name="email"
                    placeholder="Email address"
                    onChange={handleChange}
                    required
                  />
                  {!status ? (
                    <input
                      type="submit"
                      value="Subscribe"
                      className="form-control submit px-3"
                    />
                  ) : (
                    <input
                      type="submit"
                      value="Subscribing"
                      className="form-control submit px-3"
                    />
                  )}
                </div>
              </form>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-md-12 text-center">
            <p>Copyright &copy;2024 All rights reserved</p>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
