import { useEffect, useState } from "react";
import { db } from "./firebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import { useParams } from "react-router-dom";
import Navbar from "../layouts/Navbar";
import { toast } from "react-toastify";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import Swal from "sweetalert2";

function ShopDetails() {
  const { id } = useParams();
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [load, setLoad] = useState(false);
  const [message, setMessage] = useState("");
  const [products, setProducts] = useState([]);
  const [mainImage, setMainImage] = useState("");

  useEffect(() => {
    const fetchProducts = async () => {
      const querySnapshot = await getDocs(collection(db, "products"));
      const productsList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProducts(productsList);
    };

    fetchProducts();
  }, []);

  const product = products.find((p) => p.id.toString() === id);

  useEffect(() => {
    if (product && product.imageUrls.length > 0) {
      setMainImage(product.imageUrls[0]);
    }
  }, [product]);

  const sendFeedback = (templateId, variables) => {
    setLoad(true);
    window.emailjs
      .send("service_tbknuui", templateId, variables)
      .then((res) => {
        setLoad(false);
        Swal.fire({
          title: "Successful!",
          text: "We have received your message. Our team will contact you within 24 hours! Thank you.",
          icon: "success",
          confirmButtonColor: "#cd694e",
        });
        setName("");
        setMessage("");
        setEmail("");
        setPhone("");
      })
      .catch((err) => {
        setLoad(false);
        Swal.fire({
          title: "Error!",
          text: "Something went wrong: " + err.message,
          icon: "error",
          confirmButtonColor: "#cd694e",
        });
      });
  };

  const recipientNumber = "+2347077628295";
  const handleWhatsAppMessage = () => {
    if (
      name.length > 0 &&
      email.length > 0 &&
      phone.length > 0 &&
      message.length > 0
    ) {
      const whatsappMessage = `Name: ${name}%0A Email: ${email}%0A Product: ${
        product.title
      }%0A Message: ${encodeURIComponent(message)}`;
      const whatsappUrl = `https://wa.me/${recipientNumber}?text=${whatsappMessage}`;
      window.open(whatsappUrl, "_blank");
    } else {
      toast.info("Fill your details");
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    if (
      name.length > 0 &&
      email.length > 0 &&
      phone.length > 0 &&
      message.length > 0
    ) {
      const templateId = "template_6losdsk";
      const variables = {
        message: message,
        from_name: name,
        reply_to: email,
        title: product.title,
        to_name: phone,
      };
      sendFeedback(templateId, variables);
    } else {
      toast.info("Fill your details");
    }
  };

  const handleImageClick = (url) => {
    setMainImage(url);
  };

  if (!product) {
    return (
      <div>
        {" "}
        <SkeletonTheme baseColor="#ffffff" highlightColor="#a33e21">
          <p>
            <Skeleton count={10} />
          </p>
        </SkeletonTheme>
      </div>
    );
  }

  return (
    <>
      <Navbar />
      <section className="ftco-section cn">
        <div className="container">
          <div className="row">
            <div className="col-lg-8 ">
              <div className="card mb-3">
                <img
                  src={mainImage}
                  alt={product.title}
                  className="img-fluid"
                />
              </div>
              <div className="d-flex">
                {product.imageUrls.map((url, index) => (
                  <img
                    key={index}
                    src={url}
                    alt={product.title}
                    className="img-thumbnail m-2"
                    style={{
                      cursor: "pointer",
                      width: "100px",
                      height: "auto",
                    }}
                    onClick={() => handleImageClick(url)}
                  />
                ))}
              </div>
              <h4 className="mb-3">{product.title.toUpperCase()}</h4>
              <p>{product.description}</p>
            </div>
            <div className="col-lg-4 sidebar">
              <div className="sidebar-box">
                <h5>Contact Information</h5>
                <p className="" style={{ color: "red", fontWeight: "bolder" }}>
                  NB: When making an enquiry, please provide the name and
                  desired quantity for each item.
                </p>
                <form
                  onSubmit={(e) => e.preventDefault()}
                  className="search-form"
                >
                  <div className="form-group p-3">
                    <input
                      type="text"
                      value={name}
                      onChange={(e) => setName(e.target.value)}
                      className="form-control"
                      placeholder="Enter Name"
                      required
                    />
                  </div>
                  <div className="form-group p-3">
                    <input
                      type="email"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                      className="form-control"
                      placeholder="Enter Email address"
                      required
                    />
                  </div>
                  <div className="form-group p-3">
                    <input
                      type="phone"
                      value={phone}
                      onChange={(e) => setPhone(e.target.value)}
                      className="form-control"
                      placeholder="Enter Phone Number"
                    />
                  </div>
                  <div className="form-group p-3">
                    <input
                      type="text"
                      value={message}
                      onChange={(e) => setMessage(e.target.value)}
                      className="form-control"
                      placeholder="Enter Message"
                    />
                  </div>
                  <div className="form-group p-3">
                    <button
                      className="primary-btn header-btn bn text-uppercase"
                      onClick={handleSubmit}
                    >
                      {load ? "Submitting" : "Make Enquiries via Email"}
                    </button>
                  </div>
                  <div className="form-group p-3">
                    <button
                      className="primary-btn header-btn bn text-uppercase"
                      onClick={handleWhatsAppMessage}
                    >
                      Make Enquiries via WhatsApp
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ShopDetails;
