import "./App.css";
import { createBrowserHistory } from "history";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Index from "./pages/Index";
import About from "./pages/About";
import Contact from "./pages/Contact";

import Services from "./pages/Services";
import Gallery from "./pages/Gallery";
import Shop from "./pages/Shop";
import Upload from "./pages/Upload";
import LazyLoad from "react-lazyload";
import ShopDetails from "./pages/ShopDetails";

import Products from "./pages/Products";
import ImageViewer from "./components/ImageViewer";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";

function App() {
  const hist = createBrowserHistory();

  return (
    <>
      <LazyLoad height={200} once>
        <BrowserRouter history={hist}>
          <Routes>
            <Route path="/" element={<Index />}></Route>
            <Route path="/about" element={<About />}></Route>
            <Route path="/contact" element={<Contact />}></Route>
            <Route path="/services" element={<Services />}></Route>
            <Route path="/gallery" element={<Gallery />}></Route>
            <Route path="/shop" element={<Shop />}></Route>
            <Route path="/upload" element={<Upload />}></Route>
            <Route path="/login" element={<Login />}></Route>
            <Route path="/products" element={<Products />}></Route>
            <Route path="/dashboard" element={< Dashboard />}></Route>
            <Route path="/show" element={<ImageViewer />}></Route>
            <Route path="/shop/:id" element={<ShopDetails />}></Route>
            {/* <Route path="/terms" element={<Teams />}></Route>
          <Route path="/privacy" element={<Privacy />}></Route>
          <Route path="/services" element={<Services />}></Route>
        
          <Route path="/shop" element={<Products />}></Route>

          <Route path="/test" element={<Form />}></Route>
          <Route path="/refund" element={<Refund />}></Route> */}
          </Routes>
        </BrowserRouter>
        <ToastContainer />
      </LazyLoad>
      <ToastContainer />
    </>
  );
}

export default App;
