import React, { useState } from "react";
import { db, storage } from "./firebaseConfig";
import { collection, addDoc, updateDoc, doc } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { toast } from "react-toastify";
import AdminNavbar from "../layouts/AdminNavbar";
import Footer from "../layouts/Footer";

const Upload = () => {
  const access = sessionStorage.getItem("mandftoken");

  if (!access) {
    window.location.href = "/";
    sessionStorage.setItem("mandftoken", "");
    toast.info("You are not allowed to view this page.");
  }
  const [title, setTitle] = useState("");

  const [description, setDescription] = useState("");
  const [category, setCategory] = useState("");
  const [status, setStatus] = useState(false);
  const [sold, setSold] = useState("false");
  const [files, setFiles] = useState([]);

  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (e) => {
    const selectedFiles = e.target.files;
    setFiles([...files, ...selectedFiles]);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (files.length === 0) {
      alert("Please select at least one file!");
      return;
    }

    try {
      setIsLoading(true);
      // Add a new document with title and description
      const docRef = await addDoc(collection(db, "products"), {
        title: title,
        description: description,
        category: category,
        status: status,
      });

      if (docRef.id) {
        const promises = [];
        // Iterate over selected files
        for (let i = 0; i < files.length; i++) {
          const file = files[i];
          const storageRef = ref(storage, `products/${docRef.id}/${file.name}`);
          promises.push(uploadBytes(storageRef, file));
          // Wait for upload to complete and get download URL
          promises.push(
            new Promise(async (resolve, reject) => {
              try {
                const snapshot = await uploadBytes(storageRef, file);
                const downloadURL = await getDownloadURL(snapshot.ref);
                resolve(downloadURL);
              } catch (error) {
                reject(error);
              }
            })
          );
        }

        // Wait for all uploads to complete
        const downloadURLs = await Promise.all(promises);

        // Update the document with the image URLs
        await updateDoc(doc(db, "products", docRef.id), {
          imageUrls: downloadURLs.filter((url) => typeof url === "string"),
        });
      }

      toast.success("Product updated successfully");
      // Upload file to Firebase Storage
      setIsLoading(false);
      // Reset the form
      setCategory("");
      setTitle("");
      setStatus("");
      setSold("");
      setDescription("");

      setFiles([]);
    } catch (error) {
      console.error("Error uploading document: ", error);
      toast.error("Error uploading document: ", error);
    }
  };

  return (
    <>
      <AdminNavbar />
      {/* <Header name="Upload" /> */}
      <section class="ftco-section">
        <div class="container">
          <div class="row justify-content-start mb-5 pb-2">
            <div class="col-md-4 heading-section ">
              <span class="subheading subheading-with-line">
                <small class="pr-2 bg-white">Upload Products</small>
              </span>
            </div>
            <div class="col-md-8 pl-md-5 heading-section ">
              <div class="pl-md-4 border-line">
                <form onSubmit={handleSubmit}>
                  <div className="form-group">
                    <input
                      type="text"
                      value={title}
                      onChange={(e) => setTitle(e.target.value)}
                      placeholder="Title"
                      className="form-control"
                      required
                    />
                  </div>
                  <div className="form-group">
                    <select
                      type="text"
                      value={category}
                      onChange={(e) => setCategory(e.target.value)}
                      required
                      className="form-control"
                    >
                      <option value="">Select Category</option>
                      <option value="furniture">Furniture</option>
                      <option value="lighting">Lighting</option>
                      <option value="vases">Vases/Pots</option>
                      <option value="mirror">Mirrors</option>
                      <option value="plants">Plants</option>
                      <option value="accessories">Accessories</option>
                      <option value="wallart">Wall Art</option>
                    </select>
                  </div>
                  <div className="form-group">
                    <select
                      type="text"
                      value={status}
                      onChange={(e) => setStatus(e.target.value)}
                      required
                      className="form-control"
                    >
                      <option value="false">Select Status</option>
                      <option value="true">Latest</option>
                      {/* <option value="lighting">Lighting</option> */}
                    </select>
                  </div>
                  <div className="form-group">
                    <select
                      type="text"
                      value={sold}
                      onChange={(e) => setSold(e.target.value)}
                      required
                      className="form-control"
                    >
                      <option value="">Select Sold out status</option>
                      <option value="true">Sold</option>
                      <option value="false">Not Sold</option>
                      {/* <option value="lighting">Lighting</option> */}
                    </select>
                  </div>

                  <div className="form-group">
                    <textarea
                      class="form-control"
                      value={description}
                      onChange={(e) => setDescription(e.target.value)}
                      placeholder="Description"
                      required
                    ></textarea>
                  </div>
                  <div className="form-group">
                    <input
                      type="file"
                      onChange={handleFileChange}
                      className="form-control"
                      multiple // Allow multiple file selection
                      required
                    />
                  </div>
                  <div className="form-group">
                    {isLoading ? (
                      <input
                        type="submit"
                        value="Submitting"
                        disabled
                        className="btn btn-primary py-3 px-5"
                      />
                    ) : (
                      <input
                        type="submit"
                        value="Upload"
                        className="btn btn-primary py-3 px-5"
                      />
                    )}
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Upload;
