import { useEffect, useState } from "react";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { db } from "../pages/firebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import "react-loading-skeleton/dist/skeleton.css";
import { useNavigate } from "react-router-dom";

function LatestComponent() {
  const [item, setItem] = useState("all");
  const [products, setProducts] = useState([]);

  const navigate = useNavigate();

  const handleClick = (product) => {
    navigate(`/shop/${product}`);
  };

  useEffect(() => {
    const fetchProducts = async () => {
      const querySnapshot = await getDocs(collection(db, "products"));
      const productsList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      const newArray = productsList.slice(0, 4);
      setProducts(productsList);
    };

    fetchProducts();
  }, []);

  console.log(products);

  return (
    <section class="ftco-section ">
      <div class="container">
        <div class="row justify-content-start mb-5 pb-2">
          <div class="col-md-4 heading-section text-center">
            <h2 class="mb-4">EXPLORE OUR COLLECTION</h2>
            <span class="subheading subheading-with-line">
              <small class="pr-2 bg-white">LATEST PRODUCTS</small>
            </span>
          </div>
          <div class="col-md-8 pl-md-5 heading-section ">
            <div class="pl-md-4 border-line"></div>
          </div>
        </div>
      </div>
      <div class="container-wrap pb-5">
        {!products.length ? (
          <SkeletonTheme baseColor="#ffffff" highlightColor="#a33e21">
            <p>
              <Skeleton count={10} />
            </p>
          </SkeletonTheme>
        ) : (
          <div className="product-grid">
            {products
              .filter((product) => {
                if (product.status === "true") {
                  return product; // This product matches the query or query is empty
                }
              })
              .map((product, i) => (
                <div
                  key={i}
                  className="blog-entry p-3 product-container"
                  data-aos-delay="100"
                  onClick={() => handleClick(product.id)}
                >
                  <figure className="product-figure">
                    <img src={product?.imageUrls?.[0]} alt="product" />
                    {product.sold === "true" && <span className="sold-out">SOLD OUT</span>}
                  </figure>

                  <div className="product-details">
                    <p className="" onClick={() => handleClick(product.id)}>
                      {product.title.toUpperCase()}
                    </p>
                  </div>
                </div>
              ))}
          </div>
        )}

        <div class="row no-gutters mt-5">
          <div class="col text-center">
            <a href="/shop" className="primary-btn header-btn text-uppercase ">
              VIEW ALL
            </a>
          </div>
        </div>
      </div>
    </section>
  );
}
export default LatestComponent;
