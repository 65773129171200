import GalleryComponent from "../components/GalleryComponent";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import Navbar from "../layouts/Navbar";

function Gallery() {
  return (
    <>
      <Navbar />
      <Header name="Gallery" />
      <GalleryComponent />
      <Footer />
    </>
  );
}

export default Gallery;
