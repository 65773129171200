import axios from "axios";
import Toastify from "toastify";
import { useState } from "react";

const useFetch = () => {
  const [items, setItems] = useState("");
  const [loading, setLoading] = useState(false);

  const getImages = async () => {
    setLoading(true);
    try {
      const response = await axios.get(
        `https://cloudinarybackend.onrender.com/cloudinary/images/latest`
      );
      if (response.data) {
        setLoading(false);
        setItems(response.data.results);
      }
    } catch (err) {
      setLoading(false);
      if (err) {
        Toastify.error("something went wrong");
      }
    }
  };

  return { items, loading, getImages };
};

export default useFetch;
