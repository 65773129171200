/* eslint-disable jsx-a11y/label-has-for */
/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { useEffect, useState } from "react";
import { Modal } from "react-bootstrap";
import { doc, updateDoc } from "firebase/firestore";
import { firestore } from "../pages/firebaseConfig";
import { toast } from "react-toastify";

const ImageViewer = ({ show, setShow, file }) => {
  const [files, setFiles] = useState(file);

  const handleClose = () => {
    setShow(false);
  };

  useEffect(() => {
    setFiles(file);
  }, [file]);

  console.log(files, file);
  return (
    <Modal show={show} onHide={handleClose} centered>
      <Modal.Header closeButton>
        <Modal.Title style={{ paddingLeft: "2rem" }}>Image Viewer</Modal.Title>
      </Modal.Header>
      {files.map((fil) => {
        return (
          <div key={fil}>
            <img src={fil} width="100%" alt="Product Image" />
          </div>
        );
      })}
    </Modal>
  );
};

export default ImageViewer;
