function ServiceComponent() {
  return (
    <>
      <section
        className="ftco-services "
        style={{
          // backgroundColor: "#a33e21",
          backgroundImage: `url('https://res.cloudinary.com/dl91pivxv/image/upload/v1719231721/B07A2367_wd2lts.jpg')`,
          width: "100vw",
          backgroundRepeat: "no-repeat",
          backgroundSize: "contain",
          backgroundPosition: "absolute",
          opacity: "initial",
          // backgroundColor: "rgba(0, 0, 0, 0.5)",
        }}
      >
        <div className="container">
          <div className="row justify-content-start mb-5 pb-5"></div>

          <div className="row justify-content-start mt-5 py-5">
            <div className="col-md-4 heading-section "></div>
            <div className="col-md-8 ">
              <div
                style={{
                  backgroundColor: "white",
                  padding: "13px",
                }}
              >
                <h2 className="mb-4">Our Services</h2>
                <p>MandF Interiors; where creativity meets functionality.</p>
              </div>
              <div className="row d-flex">
                <div className="col-md-6 d-flex align-items-stretch">
                  <div className="media block-6 services services-2 d-block bg-light p-2 mb-4">
                    <div className="icon d-flex justify-content-center align-items-center">
                      <span className="flaticon-layers"></span>
                    </div>
                    <div className="media-body p-2 mt-3">
                      <h3 className="heading"> Personalized Interiors</h3>
                      <p>
                        We work closely with you to create interiors that are
                        uniquely tailored to your taste and lifestyle.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 d-flex align-items-stretch">
                  <div className="media block-6 services services-2 d-block bg-light p-2 mb-4">
                    <div className="icon d-flex justify-content-center align-items-center">
                      <span className="flaticon-layers"></span>
                    </div>
                    <div className="media-body p-2 mt-3">
                      <h3 className="heading"> Architectural Excellence</h3>
                      <p>
                        We provide comprehensive architectural design solutions,
                        ensuring the structural integrity and aesthetics of your
                        project.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 d-flex align-items-stretch">
                  <div className="media block-6 services services-2 d-block bg-light p-2 mb-4">
                    <div className="icon d-flex justify-content-center align-items-center">
                      <span className="flaticon-layers"></span>
                    </div>
                    <div className="media-body p-2 mt-3">
                      <h3 className="heading"> Concept to Creation</h3>
                      <p>
                        We bring your ideas to life with innovative concept
                        design, setting the foundation for an exceptional space.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 d-flex align-items-stretch">
                  <div className="media block-6 services services-2 d-block bg-light p-2 mb-4">
                    <div className="icon d-flex justify-content-center align-items-center">
                      <span className="flaticon-layers"></span>
                    </div>
                    <div className="media-body p-2 mt-3">
                      <h3 className="heading"> Space Transformation</h3>
                      <p>
                        Maximizing your space's potential through intelligent
                        layout planning and design.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 d-flex align-items-stretch">
                  <div className="media block-6 services services-2 d-block bg-light p-2 mb-4">
                    <div className="icon d-flex justify-content-center align-items-center">
                      <span className="flaticon-layers"></span>
                    </div>
                    <div className="media-body p-2 mt-3">
                      <h3 className="heading"> Project Mastery</h3>
                      <p>
                        With meticulous project management, we ensure your
                        project is execued flawlessly, on time and within
                        budget.
                      </p>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 d-flex align-items-stretch">
                  <div className="media block-6 services services-2 d-block bg-light p-2 mb-4">
                    <div className="icon d-flex justify-content-center align-items-center">
                      <span className="flaticon-layers"></span>
                    </div>
                    <div className="media-body p-2 mt-3">
                      <h3 className="heading"> Curated Finishings</h3>
                      <p>
                        We source and curate the finest furniture and decor
                        pieces, hand-picked to complement your design vision.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ServiceComponent;
