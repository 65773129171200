import AboutComponent from "../components/AboutComponent";
import Mission from "../components/Mission";
import Footer from "../layouts/Footer";
import Header from "../layouts/Header";
import Navbar from "../layouts/Navbar";

function About() {
  return (
    <>
      <Navbar />
      <Header name="About Us" />
      <AboutComponent />

      <Footer />
    </>
  );
}

export default About;
