// firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { getStorage } from "firebase/storage";

const firebaseConfig = {
  apiKey: "AIzaSyB31c9Ifa8KuRmrh3cr9uYIe924aQ7bqlI",
  authDomain: "mandf-ecommerce.firebaseapp.com",
  projectId: "mandf-ecommerce",
  storageBucket: "mandf-ecommerce.appspot.com",
  messagingSenderId: "193243261264",
  appId: "1:193243261264:web:e656f74ba8420b1ba0e455",
  measurementId: "G-LVCFXM1DSV",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const analytics = getAnalytics(app);
const storage = getStorage(app);
const firestore = getFirestore(app);

export { db, storage, firestore, analytics };
