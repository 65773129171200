import React, { useEffect, useState } from "react";
import { db } from "./firebaseConfig";
import { collection, getDocs } from "firebase/firestore";
import Navbar from "../layouts/Navbar";
import Header from "../layouts/Header";
import Footer from "../layouts/Footer";
import Skeleton, { SkeletonTheme } from "react-loading-skeleton";
import { useNavigate } from "react-router-dom";

const Shop = () => {
  const [item, setItem] = useState("all");
  const [products, setProducts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [load, setLoad] = useState(false);
  const [tag, setTag] = useState("all");
  const productsPerPage = 20;

  // Calculate the indices of the products to be displayed on the current page
  // const indexOfLastProduct = currentPage * productsPerPage;
  // const indexOfFirstProduct = indexOfLastProduct - productsPerPage;
  // const currentProducts = products.slice(
  //   indexOfFirstProduct,
  //   indexOfLastProduct
  // );

  // // Calculate the total number of pages
  // const totalPages = Math.ceil(products.length / productsPerPage);

  // Handle page change
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const navigate = useNavigate();

  const handleClick = (product) => {
    navigate(`/shop/${product}`);
  };

  useEffect(() => {
    const fetchProducts = async () => {
      const querySnapshot = await getDocs(collection(db, "products"));
      const productsList = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }));
      setProducts(productsList);
    };

    fetchProducts();
  }, []);

  //
  const shopTag = (products, tag) => {
    // Filter the products based on the selected tag
    const filteredProducts =
      tag === "all"
        ? products
        : products.filter((product) => product.category === tag);

    // Calculate the indices for pagination
    const indexOfLastProduct = currentPage * productsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - productsPerPage;

    // Get the current products to display based on pagination
    const currentProducts = filteredProducts.slice(
      indexOfFirstProduct,
      indexOfLastProduct
    );

    // Calculate the total number of pages
    const totalPages = Math.ceil(filteredProducts.length / productsPerPage);

    return (
      <>
        <div className="product-grid">
          {currentProducts.length > 0 ? (
            currentProducts.map((product, i) => (
              <div
                key={i}
                className="blog-entry p-3 product-container"
                data-aos-delay="100"
                onClick={() => handleClick(product.id)}
              >
                <figure className="product-figure">
                  <img src={product?.imageUrls?.[0]} alt="product" />
                  {product.sold === "true" && (
                    <span className="sold-out">SOLD OUT</span>
                  )}
                </figure>

                <div className="product-details">
                  <p className="" onClick={() => handleClick(product.id)}>
                    {product.title.toUpperCase()}
                  </p>
                </div>
              </div>
            ))
          ) : (
            <div>
              <p className="text-center">No result found</p>
            </div>
          )}
        </div>
        {totalPages > 1 && (
          <nav className="pagination-container">
            <ul className="pagination">
              {[...Array(totalPages)].map((_, index) => (
                <li
                  key={index}
                  className={`page-items ${
                    currentPage === index + 1 ? "actives" : ""
                  }`}
                >
                  <a
                    href="#"
                    onClick={() => paginate(index + 1)}
                    className="page-link"
                  >
                    {index + 1}
                  </a>
                </li>
              ))}
            </ul>
          </nav>
        )}
      </>
    );
  };

  return (
    <>
      <Navbar />
      <Header name="Shop" />

      <section class="ftco-section">
        <div class="container">
          <div class="row justify-content-start mb-5 pb-2">
            <div class="col-md-4 heading-section ">
              <span class="subheading subheading-with-line">
                <small class="pr-2 bg-white">Products</small>
              </span>
              <h2 class="mb-5 pb-3">Featured Products</h2>
            </div>
            <div className="col-md-8 pl-md-5 heading-section">
              <div className="pl-md-4 border-line">
                <div className="category mt-5 product-nav-categories">
                  <div className="d-flex flex-wrap">
                    <span
                      className={
                        item === "all"
                          ? "active mx-2 my-1 bord"
                          : "mx-2 my-1 bord"
                      }
                    >
                      <a
                        href="#"
                        onClick={() => {
                          setItem("all");
                          setTag("all");
                          setLoad(true);
                        }}
                      >
                        All
                      </a>
                    </span>
                    <span
                      className={
                        item === "lighting"
                          ? "active mx-2 my-1 bord"
                          : "mx-2 my-1 bord"
                      }
                    >
                      <a
                        href="#"
                        onClick={() => {
                          setItem("lighting");
                          setTag("lighting");
                          setLoad(true);
                        }}
                      >
                        Lighting
                      </a>
                    </span>
                    <span
                      className={
                        item === "furniture"
                          ? "active mx-2 my-1 bord"
                          : "mx-2 my-1 bord"
                      }
                    >
                      <a
                        href="#"
                        onClick={() => {
                          setItem("furniture");
                          setTag("furniture");
                          setLoad(true);
                        }}
                      >
                        Furniture
                      </a>
                    </span>
                    <span
                      className={
                        item === "wallart"
                          ? "active mx-2 my-1 bord"
                          : "mx-2 my-1 bord"
                      }
                    >
                      <a
                        href="#"
                        onClick={() => {
                          setItem("wallart");
                          setTag("wallart");
                          setLoad(true);
                        }}
                      >
                        Wall Art
                      </a>
                    </span>
                  </div>
                  <div className="d-flex flex-wrap">
                    <span
                      className={
                        item === "vases"
                          ? "active mx-2 my-1 bord"
                          : "mx-2 my-1 bord"
                      }
                    >
                      <a
                        href="#"
                        onClick={() => {
                          setItem("vases");
                          setTag("vases");
                          setLoad(true);
                        }}
                      >
                        Vases/Pots
                      </a>
                    </span>
                    <span
                      className={
                        item === "mirror"
                          ? "active mx-2 my-1 bord"
                          : "mx-2 my-1 bord"
                      }
                    >
                      <a
                        href="#"
                        onClick={() => {
                          setItem("mirror");
                          setTag("mirror");
                          setLoad(true);
                        }}
                      >
                        Mirrors
                      </a>
                    </span>
                    <span
                      className={
                        item === "accesories"
                          ? "active mx-2 my-1 bord"
                          : "mx-2 my-1 bord"
                      }
                    >
                      <a
                        href="#"
                        onClick={() => {
                          setItem("accesories");
                          setTag("accesories");
                          setLoad(true);
                        }}
                      >
                        Accesories
                      </a>
                    </span>
                    <span
                      className={
                        item === "plants"
                          ? "active mx-2 my-1 bord"
                          : "mx-2 my-1 bord"
                      }
                    >
                      <a
                        href="#"
                        onClick={() => {
                          setItem("plants");
                          setTag("plants");
                          setLoad(true);
                        }}
                      >
                        Plants
                      </a>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container-wrap">
          {!products.length ? (
            <SkeletonTheme baseColor="#ffffff" highlightColor="#a33e21">
              <p>
                <Skeleton count={10} />
              </p>
            </SkeletonTheme>
          ) : (
            <div className="row no-gutters  pb-5">
              {item === "all" && <>{shopTag(products, tag)}</>}

              {item === "furniture" && <>{shopTag(products, tag)}</>}

              {item === "mirror" && <>{shopTag(products, tag)}</>}

              {item === "lighting" && <>{shopTag(products, tag)}</>}

              {item === "vases" && <>{shopTag(products, tag)}</>}

              {item === "plants" && <>{shopTag(products, tag)}</>}

              {item === "accessories" && <>{shopTag(products, tag)}</>}

              {item === "wallart" && <>{shopTag(products, tag)}</>}
            </div>
          )}
        </div>
      </section>

      <Footer />
    </>
  );
};

export default Shop;
