import React from "react";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Footer from "../layouts/Footer";
import AdminNavbar from "../layouts/AdminNavbar";
import { toast } from "react-toastify";

const Dashboard = () => {
  const access = sessionStorage.getItem("mandftoken");

  if (!access) {
    window.location.href = "/";
    sessionStorage.setItem("mandftoken", "");
    toast.info("You are not allowed to view this page.");
  }
  return (
    <>
      <AdminNavbar />

      <div className="container">
        <h1> {access && <p>You are logged In</p>}</h1>
      </div>
      <Footer />
    </>
  );
};

export default Dashboard;
