import { useState } from "react";

function ContactComponent() {
  const [fields, setFields] = useState("");
  const [load, setLoad] = useState(false);
  const Swal = require("sweetalert2");

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFields({ ...fields, [name]: value });
  };

  const sendFeedback = (templateId, variables) => {
    setLoad(true);
    window.emailjs
      .send("service_tbknuui", templateId, variables)
      .then((res) => {
        setLoad(false);
        Swal.fire({
          title: "Successful!",
          text: "We have recieved your message. Our team will contact you within 24 hours! Thank you.",
          icon: "success",
          confirmButtonColor: "#cd694e",
        });
        setFields("");
      })
      // Handle errors here however you like, or use a React error boundary
      .catch((err) => {
        setLoad(false);
        Swal.fire({
          title: "Error!",
          text: "Something went wrong" + err.message,
          icon: "error",
          confirmButtonColor: "#cd694e",
        });
      });
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    const templateId = "template_6losdsk";
    const variables = {
      message: fields.message,
      from_name: fields.name,
      reply_to: fields.email,
      to_name: fields.phone,
    };
    sendFeedback(templateId, variables);
  };
  return (
    <>
      <section class="ftco-section contact-section bg-light">
        <div class="container">
          <div className="row d-flex contact-info">
            <div className="col-md-4 heading-section ">
              <h2 className="mb-4"> REACH OUT TO US</h2>
             
            </div>

            <div className="w-100"></div>
            <div className="col-md-3">
              <p>
                <span>Address:</span>
                {/* <p> Oregun, Lagos.</p> */}
                {/* <a
                  className="atag"
                  href="https://www.google.com/maps/dir//13e+Olanrewaju+St,+Oregun+101233,+Ikeja,+Lagos/data=!4m6!4m5!1m1!4e2!1m2!1m1!1s0x103b924ec3889463:0x193b4de40ab8613b?sa=X&ved=2ahUKEwiit-Kbw9yBAxVXUkEAHS_cB6gQwwV6BAgQEAA&ved=2ahUKEwiit-Kbw9yBAxVXUkEAHS_cB6gQwwV6BAgSEAQ"
                  target="_blank"
                >
                  <p>No 13e, Olanrewaju street, off Kudirat Abiola way, Oregun Lagos</p>{" "}
                </a> */}
              </p>
            </div>
            <div className="col-md-3">
              <p>
                <span>Phone:</span>{" "}
                <a href="tel:0707 762 8295">
                  {" "}
                  <p>0707 762 8295</p>
                </a>
              </p>
            </div>
            <div className="col-md-3">
              <p>
                <span>Email:</span>{" "}
                <a className="atag" href="mailto:mandfinteriors.cs@gmail.com">
                  <p>mandfinteriors.cs@gmail.com</p>
                </a>{" "}
              </p>
            </div>
            <div className="col-md-3">
              <p>
                <span>Working Hours:</span>{" "}
                <p className="bold">Monday to Friday 9am - 6pm</p>
              </p>
            </div>
          </div>
        </div>
      </section>
      <section class="ftco-section ftco-no-pt ftco-no-pb contact-section">
        <div class="container-wrap">
          <div class="row d-flex align-items-stretch no-gutters">
            <div className="col-md-6 p-5 order-md-last">
              <form onSubmit={handleSubmit}>
                <div className="form-group">
                  <input
                    name="name"
                    onChange={handleChange}
                    type="text"
                    placeholder="Your Name"
                    className="form-control"
                    required
                  />
                </div>
                <div className="form-group">
                  <input
                    name="email"
                    type="email"
                    onChange={handleChange}
                    pattern="[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{1,63}$"
                    required
                    className="form-control"
                    placeholder="Your Email"
                  />
                </div>
                <div className="form-group">
                  <input
                    name="phone"
                    type="phone"
                    onChange={handleChange}
                    className="form-control"
                    required
                    placeholder="Your Phone Number"
                  />
                </div>
                <div className="form-group">
                  <textarea
                    class="form-control"
                    name="message"
                    onChange={handleChange}
                    placeholder="Message"
                    required
                  ></textarea>
                </div>
                <div className="form-group">
                  {load ? (
                    <input
                      type="submit"
                      value="Submitting"
                      className="btn btn-primary py-3 px-5"
                    />
                  ) : (
                    <input
                      type="submit"
                      value="Send Message"
                      className="btn btn-primary py-3 px-5"
                    />
                  )}
                </div>
              </form>
            </div>
            <div class="col-md-6 d-flex align-items-stretch">
              {/* <div className="p-5">
                <img
                  src="https://png.pngtree.com/thumb_back/fh260/background/20220217/pngtree-businesswoman-using-contact-icon-interface-photo-image_29649620.jpg"
                  alt="contacts"
                  width="100%"
                  height="100%"
                />
              </div> */}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default ContactComponent;
