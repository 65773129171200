import { toast } from "react-toastify";

function AdminNavbar() {
  const navB = [
    {
      name: "dashboard",
      route: "/dashboard",
    },
    {
      name: "upload",
      route: "/upload",
    },

    {
      name: "products",
      route: "/products",
    },
  ];

  const logOut = async () => {
    window.location.href = "/";
    sessionStorage.setItem("mandftoken", "");
    toast.info("You have been logged out.");
  };
  return (
    <nav
      className="navbar navbar-expand-lg navbar-dark ftco_navbar bg-dark ftco-navbar-light"
      id="ftco-navbar"
    >
      <div className="container">
        <a className="navbar-brand" href="/">
          {" "}
          <img
            src="https://mandf.vercel.app/img/log.png"
            alt="logo"
            width="150px"
          />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#ftco-nav"
          aria-controls="ftco-nav"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="oi oi-menu"></span>
        </button>

        <div className="collapse navbar-collapse" id="ftco-nav">
          <ul className="navbar-nav ml-auto">
            {navB.map((nav) => (
              <li
                className={
                  sessionStorage.getItem("open") === nav.name
                    ? "nav-item active"
                    : "nav-item"
                }
              >
                <a
                  href={nav.route}
                  onClick={() => sessionStorage.setItem("open", nav.name)}
                  className="nav-link"
                >
                  {nav.name.toUpperCase()}
                </a>
              </li>
            ))}
            <li className="nav-item ">
              <a href="#" onClick={() => logOut()} className="nav-link">
                LOGOUT
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}

export default AdminNavbar;
