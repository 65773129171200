import vid from "../assets/vid.mp4";

function AboutComponent() {
  return (
    <section className="ftco-section">
      <div className="container pt-2 pb-2">
        
        <div className="row no-gutters">
          
          <div className="col-md-5 p-md-5 img img-2 video-background">
            <video autoPlay muted loop className="video">
              <source src={vid} type="video/mp4" />
            </video>
          </div>
          <div className="col-md-7 wrap-about pb-md-5">
            <div className="heading-section mb-5 pl-md-5">
              <div className="pl-md-5 ml-md-5">
                <span className="subheading subheading-with-line">
                  <small className="pr-2 bg-white">About</small>
                </span>
                <h2 className="mb-4">CRAFTING ELEGANCE, DEFINING YOUR SPACE</h2>
              </div>
            </div>
            <div className="pl-md-5 ml-md-5 mb-5">
              <p>Transforming your space into a masterpiece</p>
              <p>
                At the heart of our mission is the desire to turn your living or
                working environment into a canvas that vividly tells your unique
                story. With years of experience and a commitment to excellence,
                we meticulously plan and execute each detail to create spaces
                that are not only visually stunning but also highly functional.
                We believe in blending sophistication with practicality,
                ensuring every project exudes elegance while meeting your needs.
                We bring a personalized touch to every project, taking the time
                to understand your tastes and lifestyle. This allows us to
                infuse your personality into the design, creating a space that
                feels uniquely yours. From the initial concept to the final
                reveal, our collaborative process ensures your vision comes to
                life. Whether you're seeking a tranquil sanctuary, a vibrant
                entertaining area, or a productive workspace, we are dedicated
                to transforming your space into a masterpiece that inspires,
                comforts, and reflects your style.
              </p>
              <p>
                {/* <a href="#" className="btn-custom">
                  Learn More <span className="ion-ios-arrow-forward"></span>
                </a> */}
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default AboutComponent;
