import Navbar from "../layouts/Navbar";
import Footer from "../layouts/Footer";
import ScrollToTop from "react-scroll-to-top";
import AboutComponent from "../components/AboutComponent";
import ServiceComponent from "../components/ServiceComponent";
import GalleryComponent from "../components/GalleryComponent";
import ContactComponent from "../components/ContactComponent";
import LatestComponent from "../components/LatestComponent";
import { useEffect, useState } from "react";

function Index() {
  const [backgroundColor, setBackgroundColor] = useState(null);

  const colors = [
    "https://res.cloudinary.com/dl91pivxv/image/upload/v1719235205/B07A2283_oawzio.jpg",
    "https://res.cloudinary.com/dl91pivxv/image/upload/v1719235144/B07A2218_mgofwp.jpg",
    // "https://res.cloudinary.com/dl91pivxv/image/upload/v1719235213/B07A2367_ddrj50.jpg",
    "http://res.cloudinary.com/dl91pivxv/image/upload/v1719231120/B07A2244_bsudhe.jpg",
  ];

  useEffect(() => {
    const imageLoader = new Image();
    colors.forEach((color) => {
      imageLoader.src = color;
    });
  }, []); // Removed colors from dependency array to prevent unnecessary reloads

  // Change background color at interval
  useEffect(() => {
    let colorIndex = 0;

    const interval = setInterval(() => {
      setBackgroundColor(colors[colorIndex]);
      colorIndex = (colorIndex + 1) % colors.length; // Loop back to the start of the array
    }, 3000); // Change color every 3 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, []); // Removed colors from dependency array to prevent unnecessary reloads

  return (
    <>
      <div
        className="big-image"
        style={{
          filter: "blur(0.3px)",
          WebkitFilter: "blur(0.3px)",
          backgroundImage: backgroundColor ? `url(${backgroundColor})` : 'none',
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          minHeight: '100vh', // Adjust height as needed
        }}
      >
        <Navbar />
        {/* <Banner /> */}
      </div>
      <LatestComponent />
      <AboutComponent />
      <ServiceComponent />
      <GalleryComponent />
      <ContactComponent />
      <Footer />
      <ScrollToTop smooth />
    </>
  );
}

export default Index;
